import { DISTRIBUTION, FRESHNESS, VOLUME } from './variables';

export const validate = (
    data,
    checkType,
    // loadGroupBy,
    dataFieldIsVolume,
    dataFieldIsDistribution,
) => {
    const errors = {};
    /* global */
    if (!checkType) errors.checkTypes = 'Check type must be selected';
    // if (!data.table_name) errors.table_name = 'Data source required';
    // if (
    //     checkType === DISTRIBUTION &&
    //     attribute &&
    //     attribute?.['data_type'] !== 'character varying'

    // )
    //     errors.column_name = 'Invalid attribute for Distribution';
    /* freshness */
    if (checkType === FRESHNESS) {
        // if (!data['metricValue']) errors.metricValue = ``;
        if (!data['date_field']) errors.date_field = `Date field is required`;
        if (!data['latency'] || Number(data['latency']) < 0)
            errors.latency = `Latency is invalid`;
        if (!data['frequency']) errors.frequency = `Frequency is required`;
        if (!data['run_time']) errors.run_time = `Run time is required`;
        if (
            (data['frequency'] === 'Daily' && Number(data['run_day']) > 7) ||
            (data['frequency'] === 'Weekly' && Number(data['run_day']) > 7) ||
            (data['frequency'] === 'Monthly' && Number(data['run_day']) > 31) ||
            (data['frequency'] === 'Yearly' && Number(data['run_day']) > 366)
        )
            errors.run_day = `Invalid run day`;
        // if (data['frequency'] !== 'Daily' && Number(data['run_day'] < 0))
        //     errors.run_day = `Invalid run day`;
        // if (data['frequency'] !== 'Daily' && !data['run_day'])
        //     errors.run_day = `Run day is required`;
    }
    /* Volume */
    if (checkType === VOLUME) {
        // if (!data['metricValue']) errors.metricValue = `Metric Value is required`;
        if (!data['column_name']) errors.column_name = `Attribute is required`;
        if (!data['date_field']) errors.date_field = `Date field is required`;
        // if (loadGroupBy && !data['group_by_col'])
        //     errors.group_by_col = 'Group by column  is required';
        if (
            dataFieldIsVolume &&
            (!data['additional_threshold'] || data['additional_threshold'] <= 0)
        )
            errors.additional_threshold =
                'Additional threshold required or invalid';
        if (data['minimum_threshold'] < 0)
            errors.minimum_threshold = 'Minimum threshold required or invalid';
    }

    /* Distribution */
    if (checkType === DISTRIBUTION) {
        // if (!data['metricValue']) errors.metricValue = `Metric Value is required`;
        if (!data['column_name']) errors.column_name = `Attribute is required`;
        if (!data['date_field']) errors.date_field = `Date field is required`;
        if (
            dataFieldIsDistribution &&
            (!data['additional_threshold'] || data['additional_threshold'] < 0)
        )
            errors.additional_threshold =
                'Additional threshold required or invalid';
        if (data['minimum_threshold'] < 0)
            errors.minimum_threshold = 'Minimum threshold required or invalid';
    }
    return errors;
};
